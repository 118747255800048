import React, { useState, useEffect, useRef } from "react";
import Traec from "traec";

import { ErrorBoundary } from "traec-react/errors";
import { confirmDelete } from "traec-react/utils/sweetalert";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";

import {
  metricFields,
  frequencyFields,
  conversionFields,
  documentFields,
  placeholderFields,
  inputTypeFields,
} from "./forms";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";
import Moment from "moment";

import { metricCounters } from "./index";

import { mergeNodes, SubNodes } from "./node";
import { copyToCommitHandler } from "./utils";

import { DragDropHandle } from "./dragHandle";
import Im from "immutable";

import { useProjectContext } from "../context";
import { useFullIds } from "../utils/hooks";
import { Hider } from "storybook-dashboard/utils";
import { doFetch, fetcher } from "storybook-dashboard/utils/fetching";
import { Modal, useModal } from "storybook-dashboard/components/modal";
import { getAncestorPaths } from "../report/context";

const MODAL_ID = "CommonMetricModal001";

export function RequiredStar({ score }) {
  if (!score) return null;
  let isRequired = score.get("required", false);
  if (!isRequired) return null;
  return <span style={{ color: "red" }}>*</span>;
}

export function MetricFrequency({ score }) {
  if (!score) return null;
  let units = score.get("freq_unit");
  if (!units) return null;
  let freq_num = score.get("freq_num");
  let from_date = score.get("from_date");
  let from_date_str = from_date ? `from ${Moment(from_date).format("Do MMM YY")}` : "";
  return `Every ${freq_num} ${units} ${from_date_str}`;
}

const copyToClipboard = (text) => {
  console.log("Copying text to clipboard: ", text);
  let dummy = document.createElement("textarea");
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

function MetricToolTip({ score }) {
  if (!score) {
    return null;
  }
  let metric = score.get("metric") || Traec.Im.Map();
  let descr = (metric.get("description") || "").trim();
  let baseMetricId = (metric.get("uid") || "").substring(0, 8);
  let metricScoreId = (score.get("uid") || "").substring(0, 8);
  return (
    <Tooltip
      animateFill={false}
      html={
        <div className="text-left">
          <div dangerouslySetInnerHTML={{ __html: descr }} />
          <p>
            BaseMetric id: {baseMetricId}
            <br />
            ReportInput id: {metricScoreId}
          </p>
        </div>
      }
    >
      <Octicon
        name="info"
        className="ml-2"
        onClick={(e) => copyToClipboard(`"baseMetricId": "${baseMetricId}",\n"metricScoreId": "${metricScoreId}"`)}
      />
    </Tooltip>
  );
}

export function MetricAlerts(cfProps) {
  let { trackerId, commitId } = useFullIds();
  let { setModal } = useModal();
  let { fetches } = useProjectContext();
  let { conversionFactor, fromMetric, toUnit } = cfProps;

  // If we have a conversion factor then render a little badge showing the value
  if (conversionFactor || !toUnit) {
    if (!Im.isImmutable(conversionFactor)) return null;
    let f = conversionFactor?.get("factor");
    let a = Math.abs(f);
    let o = a > 100 || a < 0.01 ? f?.toExponential(1) : f?.toFixed(2);
    return (
      <span
        className="badge badge-secondary mr-1 mb-1"
        style={{ cursor: "pointer" }}
        onClick={() => setModal(<EditConversionFactorModal {...cfProps} {...{ fetches, trackerId, commitId }} />)}
      >
        {o}
      </span>
    );
  }

  let metricId = fromMetric.get("uid")?.substring(0, 8);

  return (
    <Tooltip
      animateFill={false}
      html={
        <div className="text-left">
          No conversion factor found from base metric (id: {metricId}) found to parent unit "{toUnit}"
        </div>
      }
    >
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setModal(<AddConversionFactorModal {...cfProps} {...{ fetches, trackerId, commitId }} />)}
      >
        <Octicon className="text-danger" name="alert" />
      </span>
    </Tooltip>
  );
}

function MetricAdminMenu(props) {
  let { subDocs, copyToCommit, links } = props;
  let { fetches } = useProjectContext();
  let ids = useFullIds();
  let { setModal, hideModal } = useModal();
  let docNumStr = subDocs ? (subDocs.size ? `(${subDocs.size})` : "") : "";

  // If we are displaying metrics in order to copy to another commit then show the copyTo button
  if (copyToCommit) {
    return (
      <a
        className="btn btn-sm btn-default m-0 p-0"
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          copyToCommitHandler(props);
        }}
      >
        Add
      </a>
    );
  }
  // Otherwise return the full admin dropdown
  return (
    <BSBtnDropdown
      header={
        <span>
          {docNumStr} <Octicon name="gear" />
        </span>
      }
      links={dropDownLinks({ ...props, ...ids, fetches, setModal, hideModal })}
    />
  );
}

const dropDownLinks = (props) => {
  let { setModal, conversionFactor, score, toUnit, showDocs = true } = props;

  score = score || Traec.Im.Map();
  let isHidden = score.getInPath("meta_json.hidden") || false;

  let hideChildrenIfNullOrZero = score.getIn(["meta_json", "hideChildrenIfNullOrZero"]) || false;
  let calculated = score.getIn(["meta_json", "calculated"]);
  let disableManualEntry = score.getIn(["meta_json", "disable_manual_entry"]);
  calculated = calculated === undefined ? true : calculated;

  let items = [
    {
      name: "Toggle Required",
      onClick: () => {
        toggleRequiredMetric(props);
      },
    },
    {
      name: "Toggle Highlight",
      onClick: () => {
        toggleHighlightMetric(props);
      },
    },
    {
      name: `${isHidden ? "Show" : "Hide"} in Report Due`,
      onClick: () => {
        setMetricMetaJson(props, { hidden: !isHidden });
      },
    },
    {
      name: `${hideChildrenIfNullOrZero ? "Show" : "Hide"} children if no value`,
      onClick: () => {
        setMetricMetaJson(props, { hideChildrenIfNullOrZero: !hideChildrenIfNullOrZero });
      },
    },
    {
      name: `${calculated ? "Manual Entry" : "Calculate from children"}`,
      onClick: () => {
        setMetricMetaJson(props, { calculated: !calculated });
      },
    },
    {
      name: "Set Frequency",
      onClick: () => setModal(<EditFrequencyModal {...props} />),
    },
    {
      name: "Set Placeholder",
      onClick: () => setModal(<EditPlaceholderModal {...props} />),
    },
    {
      name: "Edit raw meta-data",
      onClick: () => setModal(<EditMetaJsonModal {...props} />),
    },
    {},
    {
      name: "Set Input Type",
      onClick: () => setModal(<SetInputTypeModal {...props} />),
    },
    {},
    {
      name: "Edit this Metric",
      onClick: () => setModal(<EditMetricModal {...props} />),
    },
    {
      name: "Add sub-Metric",
      onClick: () => setModal(<AddSubMetricModal {...props} />),
    },
    {
      name: "Make parent of",
      onClick: () => setModal(<AddParentOfModal {...props} />),
    },
    {},
    {
      name: "Add File Upload",
      onClick: () => setModal(<AddDocumentModal {...props} />),
    },
    {
      name: `${showDocs ? "Hide" : "Show"} File Uploads`,
      onClick: (e) => {
        setState({ showDocs: !showDocs });
      },
    },
  ];

  // Add section relating to conversion factors
  let cfProps = { ...props, conversionFactor };
  if (toUnit) {
    if (!conversionFactor || !conversionFactor.get("uid")) {
      // Add a conversion factors
      items.push({});
      items.push({
        name: "Add conversion factor",
        onClick: () => setModal(<AddConversionFactorModal {...cfProps} />),
      });
    } else if (conversionFactor && conversionFactor.get("toUnit") && conversionFactor.get("uid")) {
      items.push({});
      items.push({
        name: "Edit/show conversion factor",
        onClick: () => setModal(<EditConversionFactorModal {...cfProps} />),
      });
      if (!conversionFactor.get("is_global") && conversionFactor.get("uid")) {
        items.push({
          name: "Delete local conversion factor",
          onClick: () => {
            deleteConversionFactor(cfProps);
          },
        });
      }
    }
  }

  if (calculated) {
    items.push({
      name: `${disableManualEntry ? "Enable" : "Disable"} Manual Entry`,
      onClick: () => {
        setMetricMetaJson(props, { disable_manual_entry: !disableManualEntry });
      },
    });
  }

  // Add delete section
  items.push({});
  items.push({
    name: "Delete",
    onClick: (e) => {
      deleteNode(props);
    },
  });

  return items;
};

const mergeConversionFactors = (data, newData) => {
  let newCF = Im.fromJS(newData);
  if (!newCF.getIn(["metric", "uid"])) {
    newCF = newCF.delete("metric").setIn(["metric", "uid"], newData.metric);
  }
  return mergeNodes(data, [newCF], "uid");
};

function AddConversionFactorModal(props) {
  let { trackerId, commitId, path: pathId, fromMetric, toUnit, fetches } = props;
  let { hideModal } = useModal();
  let metricId = fromMetric?.get("uid");
  let { data, mutate, trigger } = fetches.conversionFactors;

  let fetch = new Traec.Fetch("tracker_commit_convfactor", "post", { trackerId, commitId });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      ...data,
      metric: metricId,
      toUnit: toUnit,
    }),
    postSuccessHook: (newData) => {
      mutate(mergeConversionFactors(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title={`Add conversion factor from metric [${metricId.substring(0, 8)}] to ${toUnit}`}>
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(conversionFields).toJS()}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

function EditConversionFactorModal(props) {
  let { trackerId, commitId, path: pathId, conversionFactor, fetches } = props;
  let { hideModal } = useModal();
  let { data, mutate, trigger } = fetches.conversionFactors;

  // Get the title of the modal
  let convFactorId = conversionFactor.get("uid");
  let metricId = conversionFactor.getInPath("metric.uid") || "";
  let toUnit = conversionFactor.getInPath("toUnit");

  let fetch = new Traec.Fetch("tracker_commit_convfactor", "patch", { trackerId, commitId, convFactorId });
  fetch.updateFetchParams({
    preFetchHook: (data) => {
      console.log("POSTING DATA", data);
      return data;
    },
    postSuccessHook: (newData) => {
      console.log("EditConversionFactorModal", newData);
      //mutate(mergeConversionFactors(data, newData));
      trigger();
      hideModal();
    },
  });

  return (
    <Modal title={`Edit conversion factor from metric [${metricId.substring(0, 8)}] to ${toUnit}`}>
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(conversionFields).toJS()}
        initFields={conversionFactor}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

function deleteConversionFactor(props) {
  let { trackerId, commitId, conversionFactor, fetches } = props;
  let { data, mutate } = fetches.conversionFactors;
  let convFactorId = conversionFactor.get("uid");

  let fetch = new Traec.Fetch("tracker_commit_convfactor", "delete", { trackerId, commitId, convFactorId });
  fetch.updateFetchParams({
    postSuccessHook: () => {
      mutate(data?.filter((i) => i.get("uid") !== convFactorId));
    },
  });

  fetch.dispatch();
}

const toggleRequiredMetric = ({ score, trackerId, refId, commitId, fetches }) => {
  let path = score.get("_path");
  let isRequired = score.get("required");
  let { data, mutate } = fetches.nodes;

  fetcher(`/api/tracker/${trackerId}/commit/${commitId}/node/${path}/`, "PATCH", {
    type: "metricscore",
    node: {
      metricscore: {
        required: !isRequired,
      },
    },
  }).then(({ payload }) => {
    mutate(mergeNodes(data, payload));
  });
};

const toggleHighlightMetric = ({ score, trackerId, refId, commitId, fetches }) => {
  let path = score.get("_path");
  let period = score.get("period") || -1;
  let { data, mutate } = fetches.nodes;

  fetcher(`/api/tracker/${trackerId}/commit/${commitId}/node/${path}/`, "PATCH", {
    type: "metricscore",
    node: {
      metricscore: {
        period: period > 0 ? -1 : 1,
      },
    },
  }).then(({ payload }) => {
    mutate(mergeNodes(data, payload));
  });
};

const setMetricMetaJson = ({ score, trackerId, refId, commitId, fetches, onSuccess }, meta_json) => {
  let path = score.get("_path");
  let { data, mutate } = fetches.nodes;

  fetcher(`/api/tracker/${trackerId}/commit/${commitId}/node/${path}/`, "PATCH", {
    type: "metricscore",
    node: {
      metricscore: {
        meta_json,
      },
    },
  }).then(({ payload }) => {
    mutate(mergeNodes(data, payload));
    if (onSuccess) onSuccess();
  });
};

const setNodeMeta = ({ trackerId, refId, commitId, path, setPending, hideModal, nodeType }, meta_json) => {
  let fetch = new Traec.Fetch("tracker_node", "patch", { trackerId, refId, commitId, pathId: path });
  fetch.updateFetchParams({
    preFetchHook: (body) => {
      console.log("Setting metric meta_json", meta_json);
      return {
        type: nodeType,
        node: {
          [nodeType]: { meta_json },
        },
      };
    },
    postSuccessHook: (e) => {
      setPending(false);
      if (hideModal) {
        hideModal();
      }
    },
    postFailureHook: (data) => {
      setPending(false);
    },
  });
  fetch.dispatch();
};

function EditFrequencyModal(props) {
  let { score, trackerId, refId, commitId, path: pathId, fetches } = props;
  let { hideModal } = useModal();
  let { data, mutate } = fetches.nodes;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      type: "metricscore",
      node: {
        metricscore: {
          ...data,
          freq_unit: data.freq_unit || null,
          from_date: data.from_date || null,
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Edit Reporting Frequency">
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(frequencyFields).toJS()}
        initFields={score}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

function EditPlaceholderModal(props) {
  let { score, trackerId, refId, commitId, path: pathId, fetches } = props;
  let { hideModal } = useModal();
  let { data, mutate } = fetches.nodes;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      type: "metricscore",
      node: {
        metricscore: {
          meta_json: { ...data },
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Edit Placeholder Comment">
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(placeholderFields).toJS()}
        initFields={score.get("meta_json")}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

export function SetNodeMetaJsonInput(props) {
  let { trackerId, refId, commitId, node, nodeType, hideModal } = props;
  // Pretty-print the initial JSON with indent=4
  const inputEl = useRef(null);
  let initValue = JSON.stringify(node.getInPath("meta_json") || [], undefined, 4);
  let [value, setValue] = useState(initValue);
  let [isValid, setIsValid] = useState(true);
  let [pending, setPending] = useState(false);
  let [charsToCursor, setCharsToCursor] = useState((initValue || "").length);

  const setCursorPos = (_value) => {
    if (!isValid) {
      return;
    }
    let _textArea = inputEl.current;
    console.log("Setting cursor position", _value.length, charsToCursor);
    // Set the cursor position to the same character-position (ignoring whitespaces)
    let nChars = 0;
    for (let i = 0; i < _value.length; i++) {
      if (_value[i] !== " ") {
        nChars++;
        if (nChars == charsToCursor) {
          console.log("Setting cursor position to", i + 1);
          _textArea.selectionStart = i + 1;
          _textArea.selectionEnd = i + 1;
          break;
        }
      }
    }
  };

  // This sets cursor position when value is updated
  useEffect(() => {
    console.log("Calling setCursorPos", charsToCursor);
    setCursorPos(value);
  }, [value]);

  const onChangeHandler = (e) => {
    e.preventDefault();
    let str = e.target.value;
    try {
      let _pos = str.substring(0, inputEl.current.selectionEnd + 1).replace(/ /g, "").length;
      // Get the valid string
      let validStr = JSON.stringify(JSON.parse(str), undefined, 4);
      //console.log("String is valid JSON", validStr)
      // Set the string
      console.log("Set characters before cursor", _pos);
      setCharsToCursor(_pos);
      setIsValid(true);
      setValue(validStr);
    } catch (e) {
      //console.log("Invalid JSON", str, e)
      setIsValid(false);
      setValue(str);
    }
  };

  return (
    <React.Fragment>
      <textarea
        ref={inputEl}
        className="form-control text-monospace"
        rows="20"
        onChange={onChangeHandler}
        value={value}
      />
      {!isValid ? (
        <small className="text-danger">
          <b>Enter valid json</b>
        </small>
      ) : null}
      <button
        className="btn btn-sm btn-primary pl-2 pr-2 m-1 p-0 float-right"
        disabled={!isValid}
        onClick={(e) => {
          e.preventDefault();
          let _value = JSON.parse(value);
          console.log("Setting node meta_json", _value);
          setNodeMeta(
            {
              nodeType,
              trackerId,
              refId,
              commitId,
              hideModal,
              setPending,
              path: node.get("_path"),
            },
            _value
          );
        }}
      >
        {pending ? <div className="spinner-border spinner-border-sm" /> : "Save"}
      </button>
    </React.Fragment>
  );
}

function EditMetaJsonModal(props) {
  let { score } = props;
  let { hideModal } = useModal();
  return (
    <Modal title="Edit node meta-data">
      <SetNodeMetaJsonInput {...props} hideModal={hideModal} node={score} nodeType={"metricscore"} />
    </Modal>
  );
}

function SetInputTypeModal(props) {
  let { score, trackerId, refId, commitId, path: pathId, fetches } = props;
  let { hideModal } = useModal();
  let { data, mutate } = fetches.nodes;

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      type: "metricscore",
      node: {
        metricscore: {
          meta_json: data,
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Set Input Type">
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(inputTypeFields).toJS()}
        initFields={score.get("meta_json")}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

function AddSubMetricModal(props) {
  let { trackerId, refId, commitId, path: pathId, categoryName, fetches } = props;
  let { hideModal } = useModal();
  console.log("Adding metric for category", categoryName);

  let fetch = new Traec.Fetch("tracker_node", "post", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      path: pathId,
      type: "metricscore",
      node: {
        metricscore: {
          metric: {
            ...data,
            category: categoryName,
          },
        },
      },
    }),
    postSuccessHook: (newData) => {
      let { data, mutate } = fetches.nodes;
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Add a new sub-metric">
      <BaseFormConnected
        params={fetch.params}
        fields={Im.fromJS(metricFields).toJS()}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

function AddDocumentModal(props) {
  let { trackerId, refId, commitId, path: pathId, fetches } = props;
  let { hideModal } = useModal();
  let { data, mutate } = fetches.nodes;

  let fetch = new Traec.Fetch("tracker_node", "post", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      path: pathId,
      type: "document",
      node: {
        document: {
          name: data.name || data.title,
          description: {
            title: data.title,
            text: data.description,
          },
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  return (
    <Modal title="Add a new file upload">
      <BaseFormConnected
        params={fetch.params}
        fields={Traec.Im.fromJS(documentFields).toJS()}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

function EditMetricModal(props) {
  let { score, trackerId, refId, commitId, path: pathId, fetches } = props;
  let { hideModal } = useModal();
  let { data, mutate } = fetches.nodes;
  let baseMetricId = score.getIn(["metric", "uid"]);

  let fetch = new Traec.Fetch("tracker_node", "patch", {
    trackerId,
    refId,
    commitId,
    pathId,
  });
  fetch.updateFetchParams({
    preFetchHook: (data) => ({
      type: "metricscore",
      node: {
        metricscore: {
          metric: {
            ...data,
            uid: baseMetricId,
          },
        },
      },
    }),
    postSuccessHook: (newData) => {
      mutate(mergeNodes(data, newData));
      hideModal();
    },
  });

  let initFields = (score || Traec.Im.Map()).merge(score.get("metric"));

  return (
    <Modal title="Edit metric">
      <BaseFormConnected
        params={fetch.params}
        fields={{ ...metricFields }} // This is converted to Immutable when Modal props are stored in Redux
        initFields={initFields}
        forceShowForm={true}
        hideUnderline={true}
      />
    </Modal>
  );
}

export const formEventToData = (e) => {
  e.preventDefault();
  const formData = new FormData(e.target);
  return Object.fromEntries(formData.entries());
};

function AddParentOfModal(props) {
  let { score, path, nodesByPath } = props;
  let { hideModal } = useModal();
  let baseMetricId = score.getIn(["metric", "uid"]);
  let name = score.getIn(["metric", "name"]);
  let existing = score.getIn(["meta_json", "additional_children"], Im.List()) || Im.List();

  let ancestorPaths = Im.Set(getAncestorPaths(path));
  let toExclude = Im.Set([baseMetricId]);
  //console.log("AddParentOfModal", toExclude?.toJS(), nodesByPath?.toJS(), ancestorPaths?.toJS());
  const potentialChildren = nodesByPath
    ?.toList()
    ?.filter(
      (n) =>
        n.get("_type") === "metricscore" &&
        !toExclude.has(n.getIn(["metric", "uid"]) || ancestorPaths.has(n.get("_path")))
    );

  let options = potentialChildren.map((child, i) => {
    const uid = child.getIn(["metric", "uid"]);
    const name = child.getIn(["metric", "name"]);
    return (
      <option key={i} value={uid} selected={existing.has(uid)}>
        {name}
      </option>
    );
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const additional_children = Array.from(e.target.children.item(0).options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    console.log("ParentMetricModal submitting form", e, additional_children);
    setMetricMetaJson({ ...props, onSuccess: () => hideModal() }, { additional_children });
  };

  return (
    <Modal title={`Set ${name} as parent of:`}>
      <form className="form" onSubmit={onSubmit}>
        <select className="form-control form-control-sm" name="additional_children" multiple>
          {options}
        </select>
        <button className="btn btn-sm btn-outline-primary mt-3" type="submit">
          Save
        </button>
      </form>
    </Modal>
  );
}

const deleteNode = (props) => {
  let { score, path, fetches } = props;
  let name = score ? score.getIn(["metric", "name"]) : "";
  confirmDelete({
    text: `This will delete the Metric: ${name} including any sub-metrics and documents contained within.  Are you sure you would like to proceed?`,
    onConfirm: () => {
      let { url, mutate, data } = fetches.nodes;
      doFetch(`${url}${path}/`, "DELETE").then((response) =>
        mutate(data?.filter((i) => !i.get("path").startsWith(path)))
      );
    },
  });
};

function MetricRowLink(props) {
  let { score, hasChildren, collapsed, onCollapseClick } = props;
  let scoreId = score?.get("uid");
  if (hasChildren) {
    return (
      <a href={`#${scoreId}`} onClick={onCollapseClick}>
        <Octicon className="expand_caret" name={collapsed ? "triangle-right" : "triangle-down"} />
        {score.getIn(["metric", "name"])}
      </a>
    );
  }
  return score ? score.getInPath("metric.name") : score;
}

export const getConversionFactor = ({ score, parentScore, convFactorDetails }) => {
  let { convFactorMap } = convFactorDetails;
  let metricId = score?.getIn(["metric", "uid"]);
  let parentUnit = parentScore?.getIn(["metric", "unit"]);
  let conversionKey = Im.List([metricId, parentUnit]);

  let conversionFactor = convFactorMap?.get(conversionKey);

  // If parent and child have same units, and no conversion factor is defined, then set it to 1
  if (!conversionFactor && score?.getIn(["metric", "unit"]) == parentUnit) {
    conversionFactor = Im.fromJS({
      factor: 1,
      toUnit: parentUnit,
      metric: { uid: metricId },
      is_global: false,
    });
  }

  return {
    conversionFactor,
    fromMetric: score.get("metric"),
    toUnit: parentUnit,
  };
};

export default function MetricRow(props) {
  let { path, indentLevel, disabled, parentPath } = props;

  let bgColor = "";
  let [showChildren, setShowChildren] = useState(true);

  let { getNode, getParentPath, conversionFactorMap, nodesByPath, getMetricById, getAdditionalParents } =
    useProjectContext();
  let { trackerId } = useFullIds();

  let score = getNode(path);
  if (!score) {
    return null;
  }

  // Get the conversion factor to the parent
  parentPath = parentPath || getParentPath(path);
  let parent = getNode(parentPath);

  let cfProps = getConversionFactor({
    score,
    parentScore: parent,
    convFactorDetails: { convFactorMap: conversionFactorMap },
  });

  let highlight = (score.get("period") || -1) > 0;

  let rowNum = metricCounters.row++;

  return (
    <React.Fragment>
      <div
        className={`row ${bgColor} ${highlight ? "font-weight-bold" : ""}`}
        style={{
          borderTop: "1px solid #ddd",
          backgroundColor: (rowNum + 1) % 2 ? "#E6E6E6" : "",
        }}
      >
        <div className="col-sm-6">
          {!disabled ? (
            <DragDropHandle trackerId={trackerId} path={path} />
          ) : (
            <div className="float-left">
              <span className="ml-4"> </span>
            </div>
          )}
          <span style={{ margin: "0", marginLeft: `${indentLevel * 1.5}em` }}>
            <MetricRowLink {...props} score={score} />
          </span>
          <RequiredStar score={score} />
          <MetricToolTip score={score} />
          {/* <AdditionalChildren {...{ score, getMetricById }} /> */}
        </div>
        <div className="col-sm-3">
          <MetricFrequency score={score} />
        </div>
        <div className="col-sm-2">{score.getInPath("metric.unit")}</div>
        <div className="col-sm-1 text-right m-0 p-0">
          <ErrorBoundary title={null} className="badge badge-warning" msg={<Octicon name="bug" />}>
            <MetricAlerts {...cfProps} />
            <Hider hide={disabled}>
              <MetricAdminMenu {...{ ...props, score, nodesByPath }} />
            </Hider>
          </ErrorBoundary>
        </div>
      </div>
      <SubNodes path={path} hide={!showChildren} indentLevel={indentLevel} disabled={disabled} />
    </React.Fragment>
  );
}
